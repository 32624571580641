.contact-container::before {
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    background: url('../../assets/background.jpg');
    opacity: 0.5;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    position: absolute;
    content: '';
    z-index: -10;
    padding-bottom: 2rem;
}

.contact-container {
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    opacity: 1;
    padding-bottom:1.5rem;

    h2 {
        padding: 3rem;
        text-align: center;
        font-size: 2.2rem;
        text-decoration: 5px underline dotted #54BE90;
    }

    form {
        margin: 0 4rem;
        margin-bottom: 4rem;

        .form-control {
            width: 100%;
            margin: 0.6rem 0;
            min-height: 2.5rem;
            font-size: 120%;
            border: none;
            border-radius: 5px;
        }

        input {
            border: none;
            outline: none;
        }

        .form-control:hover, .form-control:focus {
            transform: scale(1.05);
        }
        
        textarea {
            height: 8rem;
            border: none;
            outline: none;
        }
    }

    @media screen and (min-width: 800px) {
        form {
            max-width: 800px;
            margin: 0 auto;
        }
    }  
}

#contact-btn {
    display: flex;
    justify-content: center;

    .button {
        padding: 0.5em 1.2em;
        border-radius: 0.1em;
        outline: none;
        border: none;
        cursor: pointer;  
        font-size: 1.5rem;
    }

    .button:hover, .button:focus, .button:active {
        background: #232323;
        color: whitesmoke;
    }
}

.success-msg {
    width: 330px;
    background: rgb(79, 248, 107);
    padding: 12px;
    margin: 0 auto 0.2rem auto;
    text-align: center;
    font-weight: bolder;
    font-size: 120%;
}