@media screen and (min-width: 1200px) {
    .about-info {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

@media screen and (min-width: 700px) {
    .about-text img {
        width: 650px;
    }
}

@media screen and (orientation: portrait) {
    .aboutsection-container {
        max-width: 100vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .about-img, .about-text {
            max-width: 94vw;
            margin: auto;
            overflow: hidden;
        }
    
        .about-img img {
            max-width: 450px;
        }
    
        .about-text img {
            max-width: 450px;
            height: 270px;
        }
    }
}

.aboutsection-container {
    width: 100vw;
    min-height: 80vh;
    display: flex;
    flex-direction: column;

    h2 {
        margin: 3.75rem;
        text-align: center;
        font-size: 2.2rem;
        text-decoration: 5px underline dotted #54BE90;
    }

    .about-info {
        margin: 0 auto;
        text-align:center;
    }
    
    .about-img, .about-text {
        margin: 1rem;
    }

    .about-img img {
        border-radius: 50%;
        height: 22rem;
        border: 4px solid #54BE90;
        box-shadow: 2px 8px 2px #888888;
    }

    .about-text img {
        width: 850px;
    }
}


@media screen and (max-width: 550px) {
    .about-img, .about-text {
        max-width: 100vw;
    }
    .about-img img {
        max-width: 300px;
        max-height: 300px;
    }

    .about-text {
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
}

@media screen and (max-width: 390px) {
    .about-img img {
        max-width: 300px;
        max-height: 300px;
    }
    .about-text {
        max-width: 370px;
        margin: 0 auto;
        overflow: hidden;

        img {
            max-width: 95vw;
        }
    }
}

@media screen and (max-width: 370px) {
    .about-img img {
        max-width: 270px;
        max-height: 270px;
    }
    .about-text {
        width: 355px;
    }
}

