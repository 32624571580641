.aboutme-container {
    min-height: 90vh;
}

.video-container {
    position: relative;
    top: 0;

    h2 {
        position: absolute;
        margin-top: -40%;
        margin-left: 35%;
        margin-right: 25%;
        color: #fff;
        font-size: 2rem;
        text-shadow: 2px 2px 2px #232323;
    }
}

.aboutme-info {
    text-align: center;
    // margin-top: -2rem;
    padding: 1.2rem;
    background-color: #54BE90;
    font-size: 120%;
    margin-bottom: 0.5rem;

    a {
        text-decoration: none;
        color: whitesmoke;
        text-shadow: 1px 1px #232323;
    }

    a:hover {
        text-decoration: underline;
        font-weight: bold;
    }
}