:root {
    --primary: #fff;
}

.btn {
    padding: 0.5em 1.2em;
    border-radius: 0.1em;
    outline: none;
    border: none;
    cursor: pointer;  
    font-size: 1.5rem;
}

.btn-primary {
    background-color: var(--primary);
    color: #232323;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 0.5em 1.2em;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-med {
    padding: 0.5em 1.2em;
}

.btn-lge {
    padding: 0.8em 1.4em;
    font-size: 1.2em;
}

.btn--med:hover, .btn--lge:hover {
    background: #fff;
    color: #232323;
    transition: all 0.3s ease-out;
}